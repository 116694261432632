import axios from 'axios';

export class Service {

    get = (query) => new Promise((resolve, reject) => {
        axios.get(query.url())
            .then((res) => res.data ? res.data : res)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    })

    getById = (query, id, hash = ':id') => new Promise((resolve, reject) => {
        axios.get(query.url().replace(hash, id))
            .then((res) => res.data ? res.data : res)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    })

    getAll = (query, id, hash = ':id') => new Promise((resolve, reject) => {
        axios.get(id ? query.url().replace(hash, id) : query.url())
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    })
    /**
     * @param {*} query ReactQuery object
     * @param {*} id Id of object
     * @param {*} payload payload of request
     * @param {*} hash url id prefix
     * @param {*} config header configuration
     * @returns Promise
     */
    post = (query, payload, id, hash = ':id', config) => new Promise((resolve, reject) => {
        axios.post(hash ? query.url().replace(hash, id) : query.url(), payload, config)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    })
    /**
     * 
     * @param {*} query ReactQuery object
     * @param {*} id Id of object
     * @param {*} payload payload of request
     * @param {*} hash url id prefix
     * @param {*} config header configuration
     * @returns Promise
     */
    put = (query, id, payload, hash = ':id', config) => new Promise((resolve, reject) => {
        axios.put(query.url().replace(hash, id), payload, config).then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    })

    patch = (query, id, payload, hash = ':id', config) => new Promise((resolve, reject) => {
        axios.patch(query.url().replace(hash, id), payload, config).then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    })

    delete = (query, id, hash = ':id') => new Promise((resolve, reject) => {
        axios.delete(query.url().replace(hash, id)).then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    })
}
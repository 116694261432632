import React from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';

import logo from './../assets/images/logo/darklogo.png';
import logoDark from './../assets/images/logo/logo.png';
/**
 * Devpowerbglogo
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * 
 *
 */

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
    const theme = useTheme();


    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <img src={theme.palette.mode === 'dark' ? logoDark : logo} width="160px" height="55px" alt="Zidasoft LTD"></img>
        // <Button variant="text" 
        // xs={{
        //     ':hover': {
        //         boxShadow: 'none'
        //     }
        // }}
        // md={{
        //     ':hover': {
        //         boxShadow: 'none'
        //     }
        // }}
        // sx={{
        //     ':hover': {
        //         boxShadow: 'none'
        //     }
        // }}>

        // </Button>
    );
};

export default Logo;

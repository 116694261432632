import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import Loadable from '../ui-component/Loadable';

const LandingPage = Loadable(lazy(() => import('../views/pages/landing')));
const ContactPage =  Loadable(lazy(() => import('../views/pages/contact-us/index')));
const PrivacyAndTermsPage =  Loadable(lazy(() => import('../views/pages/privacy/index')));
const ProjectsPage =  Loadable(lazy(() => import('../views/pages/projects/index')));


//-----------------------|| MAIN ROUTING ||-----------------------//

const OpenRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/',
                // '/contacts',
                // '/projects',
            ]}
        >
            <Switch location={location} key={location.pathname}>
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/contacts" component={ContactPage} />
                <Route exact path="/privacy-n-terms" component={PrivacyAndTermsPage} />
                {/* <Route exact path="/projects" component={ProjectsPage} /> */}
            </Switch>
        </Route>
    );
};

export default OpenRoutes;

export const apiEndpoints = {
    public: {
        clients: {
            get: 'cleint/:id/',
            getAll: 'client',
            post: 'client',
            put: 'client/:id/',
            delete: 'client/:id/',
            deleteAll: 'client/delete_all/'
        },
        auth: {
            register: {
                post: 'auth/register'
            },
            login: {
                post: 'auth/login'
            },
            protected: {
                post: 'auth/protected'
            }
        },
        users: {
            get: 'users/:id/',
            getAll: 'users/',
            delete: 'users/:id/'
        },
        email: {
            getAll: 'email/',
            post: 'email/',
            get: 'email/:id/',
            put: 'email/:id/',
            delete: 'email/:id/',
        }
    }
}
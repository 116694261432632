import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
import Locales from './ui-component/Locales';
import NavigationScroll from './layout/NavigationScroll';
import Snackbar from './ui-component/extended/Snackbar';

// auth provider
import { JWTProvider } from './contexts/JWTContext';
import { BaseProvider } from './contexts/BaseContext';
import * as dotenv from 'dotenv'; 

//-----------------------|| APP ||-----------------------//

const App = () => {
    dotenv.config();
    const customization = useSelector((state) => state.customization);

    return (
        <BaseProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme(customization)}>
                    <CssBaseline />
                    {/* RTL layout */}
                    <Locales>
                        <NavigationScroll>
                            <JWTProvider>
                                <Routes />
                                <Snackbar />
                            </JWTProvider>
                        </NavigationScroll>
                    </Locales>
                </ThemeProvider>
            </StyledEngineProvider>
        </BaseProvider>
    );
};

export default App;

import React from 'react';
import { Switch } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import OpenRoutes from './OpenRoutes';

// project imports

//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    return (
        <Switch>
            {/* <Route exact path="/" component={UnderConstruction} /> */}

            
            {/* <Redirect exact from="/" to={config.defaultPath} /> */}
            <React.Fragment>
                <OpenRoutes />
                <AuthenticationRoutes />

                <LoginRoutes />

                <MainRoutes />
            </React.Fragment>
        </Switch>
    );
};

export default Routes;

import React, { useState } from 'react';
import { apiEndpoints } from '../utils/api/endpoints';
import { Service } from '../services/base.service';

export const BaseContext = React.createContext(null);

const BaseProvider = (props) => {
    console.log('process.env => ', process.env);
    const config = {
        local: {
            api: {
                URL: process.env.REACT_APP_LOCAL_API,
                ROOT: process.env.REACT_APP_LOCAL_API,
            }
        },
        dev: {
            api: {
                URL: process.env.REACT_APP_PROD_API,
                ROOT: process.env.REACT_APP_PROD_API,
            }
        },
        prod: {
            api: {
                URL: process.env.REACT_APP_PROD_API,
                ROOT: process.env.REACT_APP_PROD_API
            }
        }
    };

    const setEndpoints = (mode) => {
        let endpoints = {};
        Object.keys(apiEndpoints).forEach((key) => {
            endpoints[key] = `${mode.api.URL}`;
        });
        endpoints = recursiveEndpointBuilder(endpoints, apiEndpoints, undefined);
        console.log('endpoints', endpoints);
        return endpoints;
    }


    const recursiveEndpointBuilder = (endpoints, object, pref) => {
        Object.keys(object).forEach((key) => {
            if (typeof object[key] === 'object') {
                let prefix;
                if (endpoints[key])
                    prefix = `${endpoints[key]}`;
                else
                    prefix = `${pref}`;
                endpoints[key] = {};
                console.log('prefix => ', prefix);
                recursiveEndpointBuilder(endpoints[key], object[key], prefix);
                return endpoints;
            }
            console.log("ENDPOINT => ", endpoints);
            if (pref) {
                // endpoints[key] = pref;
                endpoints[key] = `${pref}/${object[key]}`;
            } else {
                const val = endpoints[key];
                console.log("VAL => ", val);
                // endpoints[key] = val;
                endpoints[key] = `${val}/${object[key]}`;
            }
        });
        return endpoints;
    }


    const mode = {
        mode: config.local,
        endpoints: {}
    }

    const checkConfig = (params) => {
        switch (params) {
            case 'production':
                mode.mode = config.prod;
                break;
            case 'dev':
                mode.mode = config.dev;
                break;
            default:
                mode.mode = config.local;
                break;
        }
        console.log('mode => ', mode);
        mode.endpoints = setEndpoints(mode.mode);
        mode.rendred = false;
        mode.rendredPemrissions = false;
        mode.root = mode.mode;
        return mode;
    }

    const [configuration,] = useState(checkConfig(process.env.REACT_APP_STAGE));

    const [service,] = useState(new Service());

    return (
        <BaseContext.Provider
            value={{
                statics: configuration,
                root: configuration.root.api.ROOT,
                endpoints: configuration.endpoints,
                service: service
            }}>
            {props.children}
        </BaseContext.Provider>
    )
}
export { BaseProvider }